<template>
  <div class="w-100 position-relative">
    <div class="bubble" v-if="addAnimation" @animationend="addAnimation = false">

    </div>
    <div class="g-butt p-2 w-100" v-if="isInventory" @click.stop="addProduct">
      הוספה
    </div>
    <div class="g-butt p-2 w-100" v-else @click.stop="">
      אין במלאי
    </div>
  </div>
</template>

<script>
  export default {
    name: 'addProductButt',
    props: {
      item: Object,
      amount: Number,
      isInventory: Boolean
    },
    data() {
      return {
        addAnimation: false
      }
    },
    methods: {
      addProduct() {
        this.$store.commit('addProduct', {
          amount: this.amount,
          id: this.item.id
        })
        this.addAnimation = true;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bubble {
    position: absolute;
    /* top: 0%; */
    right: 45%;
    width: 10%;
    padding-top: 10%;
    /* height: 22px; */
    border-radius: 100px;
    background-color: #14c656;
    animation: add-to-cart .5s;
    z-index: 2;
  }

  @keyframes add-to-cart {
    from {
      top: 0%;
      opacity: 0;
      /* width: 22px; */
      /* height: 22px; */
      /* background-color: #14c6550a; */
    }

    to {
      top: -90%;
      opacity: 1;
      /* width: 32px; */
      /* height: 32px; */
      /* background-color: #14c656; */
    }
  }

  @media (max-width: 767.98px) {}
</style>