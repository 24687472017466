<template>
  <div class="col-md-3 mt-3 position-relative" @click="$router.push(`/ProductPage/${item.id}`)">
    <div class="label f-center" v-if="item.label">
      <img class="w-100" src="@/assets/ass5.svg" alt="">
      <h6>{{item.label}}</h6>
    </div>
    <div class="row m-md-0 shadow pb-3 pt-3 c-p">
      <div class="col-md-12 col-4 img-contain img-product"
        :style="{backgroundImage: `url(${item.image[0]})`, opacity: +item.inventory? '1' : '.5'}"></div>
      <div class="col-md-12 col-4">
        <div class="row h-100 f-center">
          <div class="col-12 f-center">
            <h4 class="fw-light mt-2 text-center">{{item.name}}</h4>
          </div>
          <div class="col-12 f-center">
            <h5 class="fw-bold text-secondary previous-price" v-if="item.previousprice">₪{{item.previousprice}}</h5>
            <h5 class="fw-bold me-2 ms-2">₪{{item.price}}</h5>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-4">
        <div class="row h-100">
          <div class="col-md-6 f-center">
            <counter class="w-100 h5 m-0" @customEvent="amount = $event" :passAmount="amount" :key="amount"
              operation="incroment" />
          </div>
          <div class="col-md-6 f-center-bottom position-relative">
            <!-- <div class="bubble" v-if="addAnimation" @animationend="addAnimation = false">

            </div>
            <div class="g-butt p-2 w-100 " @click.stop="addProduct">
              הוספה
            </div> -->
            <addProductButt :isInventory="!!+item.inventory" :item="item" :amount="amount" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import counter from '@/components/counter.vue'
  import addProductButt from '@/components/addProductButt.vue'

  export default {
    name: 'boxProduct',
    components: {
      counter,
      addProductButt
    },
    props: {
      item: Object
    },
    data() {
      return {
        amount: 1,
        // addAnimation: false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .img-product {
    padding-top: 50%;
  }

  .label {
    position: absolute;
    width: 30%;
    z-index: 1;
    right: 10%;
  }

  .label img {
    position: absolute;
    top: 0
  }

  .label h6 {
    position: relative;
    z-index: 5;
    color: #fff;
    font-size: 15px;
    margin-top: 3px
  }

  @media (max-width: 767.98px) {
    .img-product {
      padding-top: 33%;
    }

    .label {
      right: 20%;
    }
  }
</style>