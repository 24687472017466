<template>
    <div class="row mt-3">
      <div class="col">
        <h2>אולי תאהב גם <span><img class="me-2" src="@/assets/ass6.jpg" alt=""></span></h2>
      </div>
    </div>
    <div class="row">
      <boxProduct v-for="item in alsoLove" :key="item.id" :item="item" />
    </div>
</template>

<script>

  import boxProduct from '@/components/boxProduct.vue'
  import shuffle from '@/helpers/shuffle.js'

  export default {
    name: 'alsoLove',
    components: {
      boxProduct
    },
    data() {
      return {
      }
    },
    computed: {
      products() {
        return this.$store.state.products
      },
      alsoLove() {
        let lengthOfItems = this.products.length > 8 ? 8 : this.products.length - 1;
        return shuffle([...this.products]).slice(0, lengthOfItems)
      }
    }
  }
</script>

<style scoped>
img {
  width: 150px;
  margin-bottom: 2px;
}
  @media (max-width: 767.98px) {

  }
</style>