<template>
  <div class="container">
    <div class="row row-product mt-5 pt-md-4 pt-3">
      <div class="label f-center" v-if="item.label">
        <img class="w-100" src="@/assets/ass5.svg" alt="">
        <h6>{{item.label}}</h6>
      </div>
      <img class="decoration" src="@/assets/ass2.png" alt="">
      <div class="col-md-6 pb-3 mt-md-0 mt-3">
        <slideImagesProduct :imgs="item.image" />
      </div>
      <div class="col-md-6 p-5">
        <h1>{{item.name}}</h1>
        <h3 class="mt-4 fw-light">{{item.text}}</h3>
        <h4 class="mt-4 fw-light">מחיר: <span class="fw-bold text-secondary previous-price"
            v-if="item.previousprice">₪{{item.previousprice}}</span> ₪{{item.price}}</h4>
        <div class="row mt-5">
          <div class="col-md-6 f-center">
            <counter class="w-100 h3 m-0" @customEvent="amount = $event" :passAmount="amount" :key="amount"
              operation="incroment" />
          </div>
          <div class="col-md-6 f-center mt-md-0 mt-3">
            <!-- <div class="bubble" v-if="addAnimation" @animationend="addAnimation = false">

            </div>
            <div class="g-butt p-2 w-100 h3 m-0" @click.stop="addProduct">
              הוספה
            </div> -->
            <addProductButt :isInventory="!!+item.inventory" :item="item" :amount="amount" />
          </div>
        </div>
      </div>
    </div>
    <alsoLove :key="$route.params.id" />
    <!-- <div class="row mt-3">
      <div class="col">
        <h2>אולי תאהב גם</h2>
      </div>
    </div>
    <div class="row">
      <boxProduct v-for="item in randomItems" :key="item.id" :item="item" />
    </div> -->
  </div>
</template>

<script>
  // @ is an alias to /src
  import alsoLove from '@/components/alsoLove.vue'
  import counter from '@/components/counter.vue'
  import addProductButt from '@/components/addProductButt.vue'
  import slideImagesProduct from '@/components/slideImagesProduct.vue'
  // import getRandNum from '@/helpers/getRandNum.js'

  export default {
    name: 'Home',
    components: {
      counter,
      addProductButt,
      slideImagesProduct,
      alsoLove
    },
    data() {
      return {
        amount: 1,
      }
    },
    computed: {
      products() {
        return this.$store.state.products
      },
      item() {
        return this.$store.state.products.filter((val) => {
          return val.id === this.$route.params.id;
        })[0];
      },
      // randomItems() {
      //   let arr = [];
      //   let lengthOfRandom = this.products < 8 ? 8 : this.products.length - 1;
      //   while (arr.length < lengthOfRandom) {
      //     let item = this.products[getRandNum(0, this.products.length - 1)];
      //     let checkIfExsist = arr.filter((val) => {
      //       return val.id === item.id || item.id === this.$route.params.id
      //     })
      //     if (!checkIfExsist.length) {
      //       arr.push(item)
      //     }
      //   }
      //   return arr
      // }
    }
  }
</script>

<style scoped>
  .row-product {
    position: relative;
    border: solid var(--main-text-color) 3px;
    border-top: 0;
  }

  .row-product:after,
  .row-product::before {
    content: "";
    border-top: 3px solid #000;
    top: 0;
    width: 35%;
    height: 1px;
    display: block;
    position: absolute;
  }

  .row-product::before {
    left: 0;
  }

  .decoration {
    position: absolute;
    width: 30%;
    right: 34.8%;
    top: -6%;
  }

  .label {
    position: absolute;
    width: 14%;
    z-index: 1;
    right: 4%;
    top: 0;
  }

  .label img {
    position: absolute;
    top: 0
  }

  .label h6 {
    position: relative;
    font-weight: 300;
    z-index: 5;
    color: #fff;
    font-size: 20px;
    margin-top: 10px
  }

  @media (max-width: 767.98px) {

    .decoration {
      width: 54%;
      right: 22.8%;
      top: -2%;
    }

    .row-product:after,
    .row-product::before {
      width: 25%;
    }

    .label {
      width: 34%;
      right: -13%;
      top: 11%;
      transform: rotate(90deg);
    }

    .label h6 {
      font-size: 18px;
      margin-top: 2px;
    }

  }
</style>